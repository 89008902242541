var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-sidepanel-overlay",
    attrs: {
      "id": "document-sidepanel-overlay"
    },
    on: {
      "click": function ($event) {
        return _vm.overlayClicked($event);
      }
    }
  }, [_c('div', {
    attrs: {
      "id": "wrapped-header-document"
    }
  }, [_vm.template ? _c('div', {
    on: {
      "click": _vm.focusDocumentPanel
    }
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.template.title[_vm.lan]) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "document-sidepanel",
    attrs: {
      "id": "document-sidepanel"
    }
  }, [_vm.template ? _c('div', {
    staticClass: "content-loaded"
  }, [_c('div', {
    staticClass: "document-sidepanel-header"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('h4', {
    staticClass: "d-inline"
  }, [_vm._v(" " + _vm._s(_vm.template.title[_vm.lan]) + " ")]), _c('button', {
    staticClass: "transparent-button reset-button button-small",
    on: {
      "click": _vm.resetDocument
    }
  }, [_c('img', {
    staticClass: "img16",
    staticStyle: {
      "filter": "invert(1)"
    },
    attrs: {
      "src": require("../../assets/images/undo.svg"),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Reset")) + " ")])])]), _vm.loaded ? _c('div', {
    staticClass: "toggle-container",
    staticStyle: {
      "cursor": "pointer",
      "display": "inline-flex"
    },
    on: {
      "click": function ($event) {
        _vm.showPreview = !_vm.showPreview;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.showPreview ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowLivePreview")) + " ")])]) : _vm._e()]), _c('div', {
    key: _vm.formUpdater,
    ref: "sidepanelBody",
    staticClass: "document-sidepanel-body"
  }, _vm._l(_vm.template.sections, function (section, sectionIndex) {
    return _c('div', {
      key: 'section' + sectionIndex,
      ref: 'section' + sectionIndex,
      refInFor: true,
      class: 'section-wrap ' + section.name,
      style: {
        backgroundColor: sectionIndex % 2 === 0 ? '#FAFAFA' : 'white'
      }
    }, [section.title ? _c('div', {
      staticClass: "section-name"
    }, [_c('h5', [_vm._v(" " + _vm._s(section.title[_vm.lan]) + " "), section.multi === false && section.objects[0].fields[0].required === true && (section.objects[0].fields[0].input_type === 'radio' || section.objects[0].fields[0].input_type === 'radio_horizontal') ? _c('h5', {
      staticStyle: {
        "color": "red !important"
      }
    }, [_vm._v(" * ")]) : _vm._e()])]) : _vm._e(), _c('div', {
      staticClass: "section-body"
    }, [_vm._l(section.objects, function (object, objectIndex) {
      return _c('div', {
        key: 'object' + object._id
      }, [!object.cleared ? _c('div', {
        class: {
          'section-multi': section.multi
        },
        style: objectIndex === 0 ? 'margin-top: 0' : null
      }, [section.multi && (objectIndex !== 0 || section.name === 'extras') ? _c('div', {
        staticClass: "object-top-header"
      }, [_c('div', {
        staticClass: "content-small clear-button",
        on: {
          "click": function ($event) {
            return _vm.removeObject(sectionIndex, objectIndex);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": require("../../assets/images/close.svg"),
          "alt": ""
        }
      }), _vm._v(" " + _vm._s(_vm.$t("Clear").toLowerCase()) + " ")])]) : _vm._e(), _vm._l(object.fields, function (field, fieldIndex) {
        return _c('div', {
          key: 'field' + field._id,
          staticClass: "field-wrap",
          style: fieldIndex === 0 ? 'margin-top: 0' : null
        }, [!field.level || object.level === field.level ? _c('div', [(field.input_type === 'radio' || field.input_type === 'radio_horizontal') && field.title ? _c('div', {
          class: {
            'field-name': 1,
            'field-without-title': field.input_type === 'radio_horizontal'
          }
        }, [_c('h6', [_vm._v(" " + _vm._s(field.title[_vm.lan]) + " "), field.required === true ? _c('h5', {
          staticStyle: {
            "color": "red !important"
          }
        }, [_vm._v(" * ")]) : _vm._e()])]) : _vm._e(), _c('div', {
          staticClass: "field-form"
        }, [field.reference && field.reference.fieldName === 'listingName' && field.value && field.disabled ? _c('div', {
          staticClass: "edit-enable",
          on: {
            "click": function ($event) {
              return _vm.allowEditListingObject();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")]) : _vm._e(), field.input_type === 'text' || field.input_type === 'address' || field.input_type === 'companySearch' ? _c('BaseInput', {
          attrs: {
            "id": 'document-sidepanel-' + field.name,
            "placeholder": field.title ? field.title[_vm.lan] : '',
            "required": field.required,
            "disabled": field.disabled,
            "hidden": field.hidden,
            "error": _vm.showErrors && field.required && !field.value,
            "check-for-hiding": _vm.checkForHiding
          },
          on: {
            "checkout": _vm.checkForHiddenFields
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }) : _vm._e(), field.input_type === 'text_with_label' ? _c('div', [_c('h6', {
          staticClass: "text-label"
        }, [_vm._v(_vm._s(field.title[_vm.lan]))]), _c('BaseInput', {
          staticStyle: {
            "padding-bottom": "5px"
          },
          attrs: {
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "placeholder": field.description ? field.description[_vm.lan] : '',
            "required": field.required,
            "error": _vm.showErrors && field.required && !field.value
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        })], 1) : field.input_type === 'date' ? _c('BaseDropdown', {
          attrs: {
            "icon-left": "calendar_20_20.svg",
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "placeholder": field.title ? field.title[_vm.lan] : '',
            "show-dropdown-arrow": true,
            "required": field.required,
            "error": _vm.showErrors && field.required && !field.value,
            "type": "date"
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }) : field.input_type === 'dropdown' ? _c('BaseDropdown', {
          attrs: {
            "id": 'document-sidepanel-' + field.name + '-dropdown',
            "placeholder": field.title ? field.title[_vm.lan] : '',
            "show-dropdown-arrow": true,
            "required": field.required,
            "items": _vm.objectsToReturn(field.input_items),
            "error": _vm.showErrors && field.required && !field.value,
            "type": 'dropdown'
          },
          on: {
            "itemSelected": function ($event) {
              _vm.fieldUpdater++;
            }
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }) : field.input_type === 'time' ? _c('div', {
          staticClass: "row"
        }, [_c('h6', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(field.title[_vm.lan]))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: field.value,
            expression: "field.value"
          }],
          staticClass: "time-picker",
          staticStyle: {
            "min-width": "86px"
          },
          attrs: {
            "type": "time",
            "step": "600",
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "name": "time-dropdown"
          },
          domProps: {
            "value": field.value
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.$set(field, "value", $event.target.value);
            }
          }
        })]) : field.input_type === 'textarea' ? _c('BaseTextarea', {
          attrs: {
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "placeholder": field.title ? field.title[_vm.lan] : '',
            "required": field.required,
            "error": _vm.showErrors && field.required && !field.value
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }) : _vm._e(), field.input_type === 'textarea_with_label' ? _c('div', [_c('h6', {
          staticClass: "text-label"
        }, [_vm._v(_vm._s(field.title[_vm.lan]))]), _c('BaseTextarea', {
          staticStyle: {
            "padding-bottom": "5px"
          },
          attrs: {
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "placeholder": field.description ? field.description[_vm.lan] : '',
            "required": field.required,
            "error": _vm.showErrors && field.required && !field.value
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        })], 1) : _vm._e(), field.input_type === 'contactSearch' ? _c('ClientListSearch', {
          attrs: {
            "id": 'document-sidepanel-' + field.name + '-' + field._id,
            "icon-left": 'clients.svg',
            "placeholder": field.title ? field.title[_vm.lan] : '',
            "required": true,
            "error": _vm.showErrors && field.required && !field.valueObject
          },
          on: {
            "itemChosen": function ($event) {
              return _vm.choseContact(sectionIndex, objectIndex, fieldIndex, $event);
            },
            "addNew": function () {
              return _vm.addNewContact(sectionIndex, objectIndex, fieldIndex);
            },
            "ready": function ($event) {
              _vm.loaded = true;
            }
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }) : field.input_type === 'radio' ? _c('v-radio-group', {
          attrs: {
            "column": "",
            "error-messages": _vm.showErrors && field.required && !field.value ? _vm.$t('FormErrors.FieldRequired') : null
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }, _vm._l(field.input_items, function (item) {
          return _c('v-radio', {
            key: 'radio' + item._id,
            staticClass: "content",
            attrs: {
              "label": item.title && item.title.et !== 'Muu' ? item.title[_vm.lan] : '',
              "color": "orange darken-3",
              "value": item.value,
              "row": ""
            },
            scopedSlots: _vm._u([item.title.et === 'Muu' ? {
              key: "label",
              fn: function () {
                return [_c('BaseInput', {
                  attrs: {
                    "id": 'document-sidepanel-' + item.title + '-' + item._id,
                    "placeholder": item.title ? item.title[_vm.lan] : '',
                    "error": _vm.showErrors && item.required && !item.value
                  },
                  model: {
                    value: item.title.value,
                    callback: function ($$v) {
                      _vm.$set(item.title, "value", $$v);
                    },
                    expression: "item.title.value"
                  }
                })];
              },
              proxy: true
            } : null], null, true)
          });
        }), 1) : field.input_type === 'radio_horizontal' ? _c('v-radio-group', {
          attrs: {
            "row": "",
            "error-messages": _vm.showErrors && field.required && !field.value ? _vm.$t('FormErrors.FieldRequired') : null
          },
          model: {
            value: field.value,
            callback: function ($$v) {
              _vm.$set(field, "value", $$v);
            },
            expression: "field.value"
          }
        }, _vm._l(field.input_items, function (item) {
          return _c('v-radio', {
            key: 'radio_horizontal' + item._id,
            staticClass: "content",
            attrs: {
              "label": item.title ? item.title[_vm.lan] : '',
              "color": "orange darken-3",
              "value": item.value,
              "row": ""
            },
            on: {
              "change": function ($event) {
                return _vm.radioChange(sectionIndex, objectIndex, fieldIndex);
              }
            }
          });
        }), 1) : field.input_type === 'checkbox' ? _c('Checkbox', {
          attrs: {
            "checked": field.value,
            "customStyle": 'width: 16px; height: 16px; margin: 0'
          },
          on: {
            "clicked": function ($event) {
              field.value = !field.value;
            }
          }
        }) : _vm._e()], 1)]) : _vm._e()]);
      })], 2) : _vm._e()]);
    }), section.multi ? _c('div', {
      staticClass: "section-footer"
    }, [_c('div', {
      staticClass: "add-new",
      on: {
        "click": function ($event) {
          return _vm.addObject(sectionIndex);
        }
      }
    }, [_vm._v(" + " + _vm._s(_vm.$t("AddNew")) + " ")])]) : _vm._e()], 2)]);
  }), 0), _c('div', {
    staticClass: "document-sidepanel-footer"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("SaveAndExit")) + " ")]), _c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.closePanel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    attrs: {
      "name": "generate-doc-button"
    },
    on: {
      "click": _vm.generatePdf
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/checkmarkCircled.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("GenerateDocument")) + " ")])])])])]) : _c('div', {
    staticClass: "content-loading"
  }, [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "70px",
      "height": "70px"
    }
  })])]), _c('DocumentPreview', {
    attrs: {
      "company": _vm.company,
      "document": _vm.template,
      "lan": _vm.lan,
      "generatePdfUpdater": _vm.generatePdfUpdater,
      "documentUpdater": _vm.updateDocument
    }
  }), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "15"
    },
    attrs: {
      "accordion": true,
      "not-contact-view-route": true
    },
    on: {
      "closed": function ($event) {
        _vm.clientAddOpen = false;
      },
      "newAddedContact": _vm.addContact
    }
  }) : _vm._e(), _vm.documentPopoverOpen ? _c('DocumentPopover', {
    on: {
      "cancel": function ($event) {
        _vm.documentPopoverOpen = false;
      },
      "close": _vm.closePanel,
      "save": _vm.save
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }